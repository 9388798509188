import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="boper boper-hb">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="current">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
          </div>
          <section className="detox">
            <div className="section section-banner">
              <div className="wrapper">
                <div className="stamp">NOWOŚĆ</div>
                <div className="desc">
                  <p className="txt-1">
                    NEUTROGENA<sup>®</sup> Skin Detox
                  </p>
                  <p className="txt-2">dociera do</p>
                  <p className="txt-3">100% zanieczyszczeń</p>
                </div>
              </div>
            </div>
            <div className="section section-1">
              <h2 className="title">
                Odkryj linię kosmetyków do twarzy
                <br />
                <span className="big">
                  NEUTROGENA<sup>®</sup> Skin Detox
                </span>
              </h2>
              <div className="wrapper">
                <div className="col-1">
                  Aktywny tryb życia powoduje narażenie skóry na różnorodne
                  czynniki zewnętrzne, takie jak zanieczyszczenie powietrza,
                  wiatr, klimatyzację, co może prowadzić do zablokowanych porów,
                  odwodnienia, a nawet przedwczesnego starzenia się skóry.
                  <br />
                  <br />
                  Poznaj linię NEUTROGENA<sup>®</sup> Skin Detox - produkty
                  myjące dogłębnie oczyszczają , docierając do 100%
                  zanieczyszczeń, bez utraty nawodnienia, a nawilżający żel do
                  twarzy chroni i regeneruje skórę.
                  <br />
                  <br />
                  <span className="big">
                    Kompletny rytuał dla czystej <br />i pełnej blasku cery.
                  </span>
                </div>
                <div className="col-2">
                  <div className="item">
                    <img
                      src="/themes/simple/img/detox/step-1.png"
                      alt="Zanieczyszczenia"
                    />
                    <p className="text">Zanieczyszczenia</p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/detox/step-3.png"
                      alt="Makijaż i brud"
                    />
                    <p className="text">Makijaż i brud</p>
                  </div>
                  <div className="item">
                    <img
                      src="/themes/simple/img/detox/step-3.png"
                      alt="Czynniki pogodowe"
                    />
                    <p className="text">Czynniki pogodowe</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-2">
              <h2 className="title blue">
                NEUTROGENA<sup>®</sup> Skin Detox
                <br />
                <span className="big">Oczyszczająca maska 2w1</span>
              </h2>
              <div className="desc">
                Detoksykująca formuła z glinką i kwasem glikolowym może być{" "}
                <br />
                stosowana jako produkt oczyszczający lub jako <br />
                1-minutowa maska.
              </div>
              <div className="wrapper">
                <div className="col-1">
                  <div
                    style={{
                      "-webkit-text-align": "center",
                      "text-align": "center"
                    }}
                  >
                    <img src="/themes/simple/img/detox/packshot.png" />
                    <br />
                    <br />
                    <a className="btn-2" href="/product/83/">
                      Zobacz
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="item">
                    Pomaga usunąć zanieczyszczenia oraz działa przeciw
                    sszkodliwym czynnikom zewnętrznym.
                  </div>
                  <div className="item">
                    Delikatnie usuwa cząsteczki brudu, nadmiar sebum oraz
                    makijaż.
                  </div>
                  <div className="item">
                    Formuła 2w1 może być stosowana jako codzienny produkt
                    oczyszczający lub jako detoksykująca maska, która dokładnie
                    oczyszcza i odblokowuje pory dla jednolitej i rozświetlonej
                    cery.
                  </div>
                  <div className="item">Delikatna dla każdego typu skóry.</div>
                  <div className="item bad">
                    Nie powoduje utraty nawodnienia w skórze.
                  </div>
                  <div className="item bad">Nie zatyka porów.</div>
                </div>
              </div>
            </div>
            <div className="section section-3">
              <h2 className="title">
                Wypróbuj inne produkty z linii
                <br />
                <span className="big">
                  NEUTROGENA<sup>®</sup> Skin Detox
                </span>
              </h2>
              <div className="wrapper">
                <div className="col col-1">
                  <div className="desc">
                    NEUTROGENA<sup>®</sup>
                    <br /> Skin Detox
                    <span className="big">
                      <br />
                      Wygładzający
                      <br /> peeling do
                      <br /> twarzy
                    </span>
                    <br />
                    <br />
                    <a href="/product/84/" className="btn">
                      Zobacz
                    </a>
                  </div>
                  <img
                    className="img"
                    src="/themes/simple/img/detox/packshot-1.png"
                  />
                </div>
                <div className="col col-2">
                  <div className="desc">
                    NEUTROGENA<sup>®</sup>
                    <br /> Skin Detox
                    <span className="big">
                      <br />
                      Nawilżający żel
                      <br /> do twarzy 2w1
                    </span>
                    <br />
                    <br />
                    <a className="btn" href="/product/82/">
                      Zobacz
                    </a>
                  </div>
                  <img
                    className="img"
                    src="/themes/simple/img/detox/packshot-2.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
              <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
              </nav>
              <a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
